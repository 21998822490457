/**
 * Smooth Scroll
 * https://github.com/tsuyoshiwada/sweet-scroll
 */

import SweetScroll from 'sweet-scroll';

export default function smoothScroll() {
  const sweetScroll = new SweetScroll({
    before: () => {
      document.body.classList.remove('navbar--opened');
    }
  });
}
