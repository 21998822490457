/*
 * @title App
 * @description Application entry point
 */

// Polyfills
import 'Utils/_closest.polyfill.js';
import 'Utils/_matches.polyfill.js';


// Misc
// Use log() instead of console.log()
// const log = console.log.bind(console);

// Modules
import carousel from 'Modules/carousel/carousel';
import navbar from 'Modules/navbar/navbar';
import searchOverlay from 'Modules/search-overlay/search-overlay';
import * as L from 'Modules/map/leaflet';
import  'Modules/map/colorfilter';
import AOS from 'aos';
import * as basicLightbox from 'basiclightbox'
import datepicker from 'js-datepicker';

// Components
import collapse from 'Components/collapse.js';
import smoothScroll from 'Components/smooth-scroll';
import toggleElement from 'Components/toggle-element';
import {preventDefault} from "leaflet/src/dom/DomEvent";

window.changeInfo = (tunnel) => {
  let tabs = document.querySelectorAll('.tunnel');
  let radios = document.querySelectorAll('input[name="tunnel"]');
  for (let i = 0; i < tabs.length; i++) {
    if (tabs.item(i).id === tunnel) {
      tabs.item(i).classList.remove("is-hidden");
      radios.item(i).click();
    } else {
      tabs.item(i).classList.add("is-hidden");
    }
  }

};

document.addEventListener('DOMContentLoaded', function() {

  // Modules
  window.onload = function() {
    carousel();
  }
  navbar();
  searchOverlay();
  AOS.init({
    once: true
  });

  // Components
  collapse();
  smoothScroll();
  toggleElement();

  var mymap = L.map('mapid',{ scrollWheelZoom: false
});

  let myFilter = [
    'blur:0px',
    'brightness:95%',
    'contrast:130%',
    'grayscale:100%',
    'hue:290deg',
    'opacity:100%',
    'invert:100%',
    'saturate:300%',
  ];

  L.tileLayer.colorFilter('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
    maxZoom: 18,
    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    id: 'mapbox/streets-v11',
    tileSize: 512,
    zoomOffset: -1,
    filter: myFilter
  }).addTo(mymap);

  let flyIcon = L.icon({
    iconUrl: '/assets/img/pin.svg',

    iconSize:     [62, 54], // size of the icon
    shadowSize:   [0, 0], // size of the shadow
    iconAnchor:   [31, 54], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0],  // the same for the shadow
    popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
  });


  let markerArray = [];
  let freezone = L.marker([55.237290, 37.525597], {icon: flyIcon});
  let flow = L.marker([55.683321, 37.785805], {icon: flyIcon});
  let vacuum = L.marker([55.8123501, 37.6865193], {icon: flyIcon});
  markerArray.push(freezone);
  markerArray.push(flow);
  markerArray.push(vacuum);
  let group = L.featureGroup(markerArray).addTo(mymap);
  mymap.fitBounds(group.getBounds());
  //console.log(markerArray);

  freezone.button = document.getElementById('freezone');
  freezone.flyTo = () => { mymap.flyTo(freezone.getLatLng(),15)};
  freezone.info = () => { freezone.flyTo; popup.show(); window.changeInfo("freezoneInfo") };
  freezone.on('click', freezone.info);
  freezone.button.onclick = (e) => {preventDefault(e); freezone.info()};
  freezone.button.onmouseover = freezone.flyTo;

  flow.button = document.getElementById('flow');
  flow.flyTo = function(){ mymap.flyTo(flow.getLatLng(),15) };
  flow.info = () => { flow.flyTo; popup.show(); window.changeInfo("flowInfo") };
  flow.on('click', flow.info);
  flow.button.onclick = (e) => {preventDefault(e); flow.info()};
  flow.button.onmouseover = flow.flyTo;

  vacuum.button = document.getElementById('vacuum');
  vacuum.flyTo = function(){ mymap.flyTo(vacuum.getLatLng(),15) };
  vacuum.info = () => { vacuum.flyTo; popup.show(); window.changeInfo("vacuumInfo") };
  vacuum.on('click', vacuum.info);
  vacuum.button.onclick = (e) => {preventDefault(e); vacuum.info()};
  vacuum.button.onmouseover = vacuum.flyTo;

  const popup = basicLightbox.create(
      document.querySelector('template[id="tunnelInfo"]'),{
        onShow: (instance) => {
          instance.element().querySelector('img[class="modal-close"]').onclick = instance.close;
          instance.element().querySelector('button[id="freezoneOrder"]').onclick = instance.close;
          instance.element().querySelector('button[id="flowOrder"]').onclick = instance.close;
          instance.element().querySelector('button[id="vacuumOrder"]').onclick = instance.close;
        }
      }
  );

  const thankyou = basicLightbox.create(
      document.querySelector('template[id="thankyou"]'),{
        onShow: (instance) => {
          instance.element().querySelector('img[class="modal-close"]').onclick = instance.close
        }
      }
  );

  let tomorrow = new Date(Date.now());
  tomorrow.setDate(tomorrow.getDate() + 1);
 datepicker('#date', {
    startDay: 1,
    customDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    customMonths: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    formatter: (input, date) => {
      const value = date.toLocaleDateString()
      input.value = value // => '1/1/2099'
    },
   onSelect: () => {
     window.dataCheck();
   },
   dateSelected: tomorrow,
   minDate: tomorrow
  });

  window.dataCheck();

  const submit = document.getElementById('submit');
  submit.onclick = function(){
    let error = document.getElementById('errortext');
    error.classList.add('is-hidden');
    let name = document.getElementById('contact');
    name.classList.remove('error');
    let phone = document.getElementById('phone');
    phone.classList.remove('error');
    let email = document.getElementById('email');
    email.classList.remove('error');
    let date = document.getElementById('date').value;
    let tunnel = document.querySelector('input[name="tunnel"]:checked').value;
    let peoples = document.querySelector('input[name="peoples"]:checked').value;
    let day_part = document.querySelector('input[name="order_time"]:checked').value;
    let time = document.querySelector('input[name="time"]:checked').value;
    let price = document.querySelector('input[name="time"]:checked').getAttribute("data-price");
    if(name.value.trim() == ''){
      name.classList.add('error');
      error.classList.remove('is-hidden');
      error.innerText = 'Ошибка: Поле Имя необходимо заполнить';
      return false
    }
    if(phone.value.trim() == ''){
      phone.classList.add('error');
      error.classList.remove('is-hidden');
      error.innerText = 'Ошибка: Поле Телефон необходимо заполнить';
      return false
    }
    if(email.value.trim() == ''){
      email.classList.add('error');
      error.classList.remove('is-hidden');
      error.innerText = 'Ошибка: Поле email необходимо заполнить';
      return false
    }

    let data = new FormData();
    data.append('name', name.value );
    data.append('phone', phone.value );
    data.append('email', email.value );
    data.append('date', date );
    data.append('day_part', day_part );
    data.append('tunnel', tunnel );
    data.append('persons', peoples );
    data.append('time', time );
    data.append('price', price );

    fetch('https://fly-booking.ru/submit.php', {
      method: 'POST',
      body: data
    }).then(response => response.text()).then((response) => { if(response=='ok'){thankyou.show()}});
  }

});

window.dataCheck = function(){
  let flyDate = document.getElementById('date').value;
  let flyTunnel = document.querySelector('input[name="tunnel"]:checked').value;
  let flyPeoples = document.querySelector('input[name="peoples"]:checked').value;
  let url = `https://fly-booking.ru/api.php?tunnel=${flyTunnel}&person=${flyPeoples}&date=${flyDate}`;
  fetch(url).then(result => result.json()).then((result) => {window.buildButtons(result)});
};

window.buildButtons = function(data){
  let buttonContainer = document.getElementById('buttonContainer');
  let buttons = Object.keys(data).reduce((acc,key, index) => {
    return `${acc}<input type="radio" class="stv-radio-button" onchange="showPrice()" name="time" data-price="${data[key]}" value="${key}" id="tm${index}" />
    <label for="tm${index}">${key}</label>`;
  }, '');
  buttonContainer.innerHTML = buttons;
  document.getElementById('tm0').checked = true;
  window.showPrice();
};

window.showPrice = function () {
  let price = document.querySelector('input[name="time"]:checked').getAttribute("data-price");
  document.getElementById('price').innerText = price;
}
